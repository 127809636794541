<template>
  <vx-card class="opening-balance-page main-box top-zero-radius">
    <div class="w-full md:flex">

      <div class="md:w-1/3 p-2 flex-1" v-if="assets.children && assets.children.length">
        <div class="assets-tree h-100">
          <div class="header">
            <span class="useral-font-weight-bold">{{ $t('accountancy.openingBalance.labels.assets') }}</span>

            <div class="inline-block float-right useral-font-weight-bold text-success">{{
                addComma(Math.abs(this.assets.balance))
              }}
              {{ $locale.currency() }}
            </div>
          </div>

          <div class="body">
            <crud-tree v-for="(item, item_index) in assetsTrees"
                       :key="item_index"
                       :tree="item"
                       :options="assetsTreesOptions"
                       :hide-options="true"
                       :first="true"
                       :first-hide="true"
                       @row:clicked="handleTreeClicked($event)"/>
          </div>
        </div>
      </div>

      <div class="md:w-1/3 p-2 flex-1" v-if="liabilities.children && liabilities.children.length">
        <div class="liabilities-tree h-100">
          <div class="header">
            <span class="useral-font-weight-bold">{{ $t('accountancy.openingBalance.labels.liabilities') }}</span>

            <div class="inline-block float-right useral-font-weight-bold"
                 :class="{'text-danger': this.liabilities.balance < 0, 'text-success': this.liabilities.balance > 0}">
              {{ addComma(Math.abs(this.liabilities.balance)) }} {{ $locale.currency() }}
            </div>
          </div>

          <div class="body">
            <crud-tree v-for="(item, item_index) in liabilitiesTrees"
                       :key="item_index"
                       :tree="item"
                       :options="liabilitiesTreesOptions"
                       :hide-options="true"
                       :first="true"
                       :first-hide="true"
                       @row:clicked="clickedOpeningBalance = $event, openingBalanceClickedPromptStatus = true"/>
          </div>
        </div>
      </div>

      <div class="md:w-1/3 p-2 flex-1" v-if="equity.children && equity.children.length">
        <div class="equity-tree h-100">
          <div class="header">
            <span class="useral-font-weight-bold">{{ $t('accountancy.openingBalance.labels.equity') }}</span>

            <div class="inline-block float-right useral-font-weight-bold"
                 :class="{'text-danger': this.equity.balance < 0, 'text-success': this.equity.balance > 0}">
              {{ addComma(Math.abs(this.equity.balance)) }}
              {{ $locale.currency() }}
            </div>
          </div>

          <div class="body">
            <crud-tree v-for="(item, item_index) in equityTrees"
                       :key="item_index"
                       :tree="item"
                       :options="equityTreesOptions"
                       :hide-options="true"
                       :first="true"
                       :first-hide="true"/>
          </div>
        </div>
      </div>

    </div>

    <!-- opening balance components -->

    <vs-prompt
      class="p-0"
      v-if="Object.keys(clickedOpeningBalance).length > 0"
      :class="[{'big-prompt': clickedOpeningBalance.page !== 'products'}, {'very-big-prompt': clickedOpeningBalance.page === 'products'}]"
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="openingBalanceClickedPromptStatus"
      @close="openingBalanceClickedPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 pr-1 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="!$store.state.helper.openingBalanceLocked" @click="handleClick('InsertOpeningInventoryBTN')/*, openingBalanceClickedPromptStatus = false*/">
              <custom-icon icon="PLUS" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ clickedOpeningBalance.title }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="openingBalanceClickedPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <banks-opening-balance v-if="clickedOpeningBalance.page === 'banks'"/>
        <cash-boxes-opening-balance v-if="clickedOpeningBalance.page === 'cashed'"/>
        <users-opening-balance v-if="clickedOpeningBalance.page === 'creditorUsers'" type="creditor"/>
        <users-opening-balance v-if="clickedOpeningBalance.page === 'debtorUsers'" type="debtor"/>
      </div>

    </vs-prompt>

    <!-- /opening balance components-->


    <button v-show="false" id="unlockOpeningBalance" @click="handleLockOpeningBalance(false)"/>
    <button v-show="false" id="lockOpeningBalance" @click="handleLockOpeningBalance(true)"/>

    <custom-dialog ref="unlockOpeningBalanceConfirmation"
                   :title="$t('accountancy.openingBalance.confirmations.unlock.title')"
                   :body="$t('accountancy.openingBalance.confirmations.unlock.body')"
                   @accept="lockOpeningBalance(false)"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {addComma} from '@/assets/js/functions'
import {
  getOpeningInventories,
  lockOpeningInventories,
  unlockOpeningInventories
} from '@/http/requests/accountancy/openingInventories'
import CustomDialog from '@/components/customDialog/customDialog'
import CustomIcon from '../../../../components/customIcon/customIcon'
import BanksOpeningBalance from '../../treasury/banks/openingBalance/banksOpeningBalance'
import CashBoxesOpeningBalance from '../../treasury/cashBoxes/openingBalance/cashBoxesOpeningBalance'
import UsersOpeningBalance from '../../customers/openingBalance/usersOpeningBalance'

export default {
  name: 'index',
  metaInfo () {
    return {
      title: this.$t('accountancy.openingBalance.title')
    }
  },
  components: {
    UsersOpeningBalance,
    CashBoxesOpeningBalance,
    BanksOpeningBalance,
    CustomIcon,
    CustomDialog},
  data () {
    return {
      assetsTreesOptions: {
        type: 'openingBalanceType'
      },
      liabilitiesTreesOptions: {
        type: 'openingBalanceType'
      },
      equityTreesOptions: {
        type: 'openingBalanceType'
      },
      assets: [],
      assetsTrees: [],
      liabilities: [],
      liabilitiesTrees: [],
      equity: [],
      equityTrees: [],
      lockStatus: false,
      openingBalanceClickedPromptStatus: false,
      clickedOpeningBalance: {},
      actions: [
        {
          /*toolbar: [
            {
              id: 'lockOpeningBalance',
              icon: 'icon-lock',
              iconPack: 'feather',
              color: 'danger'
            },
            {
              id: 'unlockOpeningBalance',
              icon: 'icon-unlock',
              iconPack: 'feather',
              color: 'disable'
            }
          ]*/
        },
        {
          toolbar: [
            {
              id: 'lockOpeningBalance',
              icon: 'icon-lock',
              iconPack: 'feather',
              color: 'disable'
            },
            {
              id: 'unlockOpeningBalance',
              icon: 'icon-unlock',
              iconPack: 'feather',
              color: 'success'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.$nextTick(() => {
      // this.$store.dispatch('updateNavbarActions', this.actions[1])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    this.getOpeningInventories()
  },
  methods: {
    handleTreeClicked (tree) {
      if (tree.hasOwnProperty('page') && tree.page) {
        this.clickedOpeningBalance = tree
        this.openingBalanceClickedPromptStatus = true
      }
    },
    handleLockOpeningBalance (status) {
      if (status && !this.lockStatus) {
        this.lockOpeningBalance(true)
      } else if (status && this.lockStatus) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('accountancy.openingBalance.notifications.locked'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      } else if (!status && this.lockStatus) {
        if (this.lockStatus) this.$refs.unlockOpeningBalanceConfirmation.showDialog()
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('accountancy.openingBalance.notifications.unLocked'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      }
    },
    lockOpeningBalance (status) {
      if (status) {
        lockOpeningInventories().then(() => {
          setTimeout(() => {
            // this.$store.dispatch('updateNavbarActions', this.actions[0])
            this.$store.dispatch('helper/openingBalanceLocked')
          }, 50)

          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('accountancy.openingBalance.notifications.lock.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('accountancy.openingBalance.notifications.lock.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      } else {
        unlockOpeningInventories().then(() => {
          setTimeout(() => {
            // this.$store.dispatch('updateNavbarActions', this.actions[1])
            this.$store.dispatch('helper/openingBalanceUnlocked')
          }, 50)

          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('accountancy.openingBalance.notifications.unlock.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('accountancy.openingBalance.notifications.unlock.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }

      this.lockStatus = status
    },
    getOpeningInventories () {
      this.$vs.loading()
      this.liabilitiesTrees = []
      this.equityTrees = []
      this.assetsTrees = []

      getOpeningInventories().then(response => {
        /*if (response.data.data.status === 2) {
            this.$store.dispatch('updateNavbarActions', this.actions[0])
            this.$store.dispatch("helper/openingBalanceLocked")
            this.lockStatus = true
        }*/

        this.assets = response.data.data.assets
        this.liabilities = response.data.data.debt
        this.equity = response.data.data.fund

        this.initiateTree()
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    initiateTree () {
      this.assets.children.forEach((type) => {
        this.assetsTrees.push(this.getTree(type, true))
      })

      this.liabilities.children.forEach((type) => {
        this.liabilitiesTrees.push(this.getTree(type, true))
      })

      this.equity.children.forEach((type) => {
        this.equityTrees.push(this.getTree(type, true))
      })

      this.$vs.loading.close()
    },
    getTree (type, first = false) {
      const data = {
        parent: {
          id: type.id,
          title: type.name,
          code: type.code,
          type: type.type,
          balance: type.balance * -1,
          lock: type.lock,
          page: type.page,
          parent_id: type.parent_id
        },
        children: [],
        options: {
          firstHide: !first,
          insertEnable: false,
          updateEnable: false,
          deleteEnable: false
        }
      }

      if (type.hasOwnProperty('store_id')) {
        data.parent.store_id = type.store_id
      }

      if (type.children && type.children.length > 0) {
        type.children.forEach((child) => {
          data.children.push(this.getTree(child))
        })
      }

      return data
    },
    addComma ($val) {
      return addComma($val)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.openingBalanceChanged': {
      handler (val) {
        if (val) {
          this.getOpeningInventories()
        }
      },
      deep: true
    },
    '$store.state.helper.openingBalanceDeleted': {
      handler (val) {
        if (val) {
          this.getOpeningInventories()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.opening-balance-page {

  .h-100 {
    height: 100%;
  }

  .header {
    padding: 10px;
    border-bottom: 1px solid #cecece;
  }

  .body {
    padding: 15px;
  }

  .assets-tree, .liabilities-tree, .equity-tree {
    border: 1px solid #cecece;
    border-radius: .5rem;
  }

  .assets-tree {
    border-color: #5ece84;
    background: #5ece8411;

    .header {
      border-color: #5ece84;
    }
  }

  .liabilities-tree {
    border-color: #dd0000;
    background: #dd000011;

    .header {
      border-color: #dd0000;
    }
  }

  .equity-tree {
    border-color: #83bcff;
    background: #83bcff11;
    /*line-height: 35px;
    width: 200px;
    height: 150px;
    padding: 25px;

    .divider {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      background: #cecece;
    }*/

    .header {
      border-color: #83bcff;
    }
  }

  .plus-icon {
    width: 35px;
    vertical-align: middle;
  }

  .table-cell {
    display: table-cell;
  }
}
</style>
